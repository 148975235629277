<template>
  <div>
    <br />
    <br />
    <form class="siret">
      <label>Merci de nous indiquer votre adresse email :</label>
      <input
        type="text"
        id="email"
        v-model="email"
        name="text"
      />
      <button @click="checkForm">VALIDER</button>
    </form>
    <br />
    <br />
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Jouez",
  data: function() {
    return {
      email: undefined,
    };
  },
  methods: {
   checkForm: async function (e) {
     e.preventDefault();
var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   if(re.test(this.email)) {
      try {
        let data = await axios.get(
          "https://api.lucien.ai/api/operations-custom/atlantic-takao/checkemail/" + this.email.trim()
        );
        
        if(data.status == 200) {
          if(data.data.count == 1) {
              this.$swal({
                type: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#C94330',
                text: 'Votre adresse email est déjà enregistrée. Un email vous a été envoyé.'
              })
          } else {
             localStorage.setItem('email', this.email.trim())
             this.$router.push("/form");
          }
        } else {
        }
      } catch(er) {
        this.$swal({
          type: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#C94330',
          text: 'Une erreur est survenue'
        })  
      }   
    } else {
        this.$swal({
          type: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#C94330',
          text: 'Cette adresse email est incorrecte'
        })    
    }
    return false
    },      
    getResult(result) {
      alert("Your sumbmission id is : " + result._id);
    },
    checkStep(step) {
      this.step = step;
    },
  },
};
</script>
<style lang="scss">
.siret {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    width: 90% !important;
  }

  input {
    width: 30% !important;
    margin-right: 2%;
    @media (max-width: 768px) {
      width: 100% !important;
      margin-right: unset;
      margin-bottom: 5%;
    }
  }

  label {
    margin-left: -8%;
    @media (max-width: 768px) {
      width: 100% !important;
      margin-left: unset;
      text-align: left;
    }
  }

  button {
    width: 150px !important;
    height: 44px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
